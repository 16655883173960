import React, { useState, useEffect } from "react";
import Portfolio from "../Items/Portfolio";

const filters = [
	{
		id: 1,
		name: "All Projects",
	},
	{
		id: 2,
		name: "Web",
	},
	{
		id: 3,
		name: "Audio",
	},
	{
		id: 4,
		name: "eCommerce",
	},
	{
		id: 5,
		name: "eLearning",
	},
];

const allData = [
	{
		id: 1,
		name: "headversity (Website)",
		category: ["web", "wordpress"],
		image: "images/portfolio/headversity.png",
		slug: "headversity",
	},
	{
		id: 2,
		name: "The Koala Platform",
		category: ["web", "saas", "elearning", "app"],
		image: "images/portfolio/koala.png",
		slug: "koala-platform",
	},
	{
		id: 3,
		name: "Respect Group Inc Website",
		category: ["web", "wordpress"],
		image: "images/portfolio/rgi.png",
		slug: "respect-group-inc",
	},
	{
		id: 4,
		name: "The Sheldon Kennedy Show",
		category: ["web", "wordpress", "podcast", "audio"],
		image: "images/portfolio/thesheldonkennedyshow.png",
		slug: "the-sheldon-kennedy-show",
	},
	{
		id: 5,
		name: "Canadian DNA Services",
		category: ["web", "wordpress", "ecommerce"],
		image: "images/portfolio/canadian-dna-services.png",
		slug: "canadian-dna-services",
	},
	{
		id: 6,
		name: "Stay In The Game",
		category: ["web", "saas", "elearning"],
		image: "images/portfolio/stay-in-the-game.png",
		slug: "stay-in-the-game",
	},
	{
		id: 7,
		name: "Vapor Jackpot",
		category: ["web", "wordpress", "ecommerce", "app"],
		image: "images/portfolio/vapor-jackpot.png",
		slug: "vapor-jackpot",
	},
	{
		id: 8,
		name: "Tank Plumbing Ltd.",
		category: ["web", "wordpress"],
		image: "images/portfolio/tankplumbing.png",
		slug: "tank-plumbing",
	},
	{
		id: 9,
		name: "Dragonball",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/dragonball.png",
		slug: "dragonball",
	},
	{
		id: 10,
		name: "Dragonball GT",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/dragonball-gt.png",
		slug: "dragonball-gt",
	},
	{
		id: 11,
		name: "Mega Man X: Command Mission",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/mega-man-x.png",
		slug: "mega-man-x",
	},
	{
		id: 12,
		name: "Mega Man Powered Up",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/mega-man-powered-up.png",
		slug: "mega-man-powered-up",
	},
	{
		id: 13,
		name: "Mega Man X8",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/mega-man-x8.png",
		slug: "mega-man-x8",
	},
	{
		id: 14,
		name: "Mobile Fighter G Gundam",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/g-gundam.png",
		slug: "g-gundam",
	},
	{
		id: 15,
		name: "Gregory Horrow Show",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/gregory-horror-show.png",
		slug: "gregory-horror-show",
	},
	{
		id: 16,
		name: "Crimson Tears",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/crimson-tears.png",
		slug: "crimson-tears",
	},
	{
		id: 17,
		name: "DNA Integrated Cybernetic Enterprises",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/dice.png",
		slug: "dice",
	},
	{
		id: 18,
		name: "Betterman",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/betterman.png",
		slug: "betterman",
	},
	{
		id: 19,
		name: "Healthy Youth Relationships",
		category: ["web", "saas", "elearning"],
		image: "images/portfolio/healthy-youth-relationships.png",
		slug: "healthy-youth-relationships",
	},
	{
		id: 20,
		name: "Lacing Up",
		category: ["web", "saas", "elearning"],
		image: "images/portfolio/lacing-up.png",
		slug: "lacing-up",
	},
	{
		id: 21,
		name: "Simply Delivery",
		category: ["web", "wordpress", "ecommerce", "app"],
		image: "images/portfolio/simply-delivery.png",
		slug: "simply-delivery",
	},
	{
		id: 22,
		name: "Erika Navarro",
		category: ["web", "wordpress"],
		image: "images/portfolio/erika-navarro.png",
		slug: "erika-navarro",
	},
	{
		id: 23,
		name: "Aarvak Services",
		category: ["web", "wordpress"],
		image: "images/portfolio/aarvak-services.png",
		slug: "aarvak-services",
	},
	{
		id: 24,
		name: "Johnston Power & Energy",
		category: ["web", "wordpress"],
		image: "images/portfolio/johnston-power.png",
		slug: "aarvak-services",
	},
	{
		id: 25,
		name: "Bulldog Moviing",
		category: ["web", "wordpress"],
		image: "images/portfolio/bulldog-moving.png",
		slug: "bulldog-moving",
	},
	{
		id: 26,
		name: "Contour Earthmoving Ltd",
		category: ["web", "custom", "flash"],
		image: "images/portfolio/contour.png",
		slug: "contour",
	},
	{
		id: 27,
		name: "Rednex Bar & Grill",
		category: ["web", "custom"],
		image: "images/portfolio/rednex.png",
		slug: "rednex",
	},
	{
		id: 28,
		name: "Linkdump",
		category: ["web", "custom"],
		image: "images/portfolio/linkdump.png",
		slug: "linkdump",
	},
	{
		id: 29,
		name: "RSSDump",
		category: ["web", "custom"],
		image: "images/portfolio/rssdump.png",
		slug: "rssdump",
	},
	{
		id: 30,
		name: "AltaLink ERP Training",
		category: ["web", "saas", "elearning", "captivate", "scorm"],
		image: "images/portfolio/altalink.png",
		slug: "altalink",
	},
	{
		id: 31,
		name: "Rampira",
		category: ["web", "saas", "elearning", "captivate", "scorm"],
		image: "images/portfolio/rampira.png",
		slug: "rampira",
	},
	{
		id: 32,
		name: "The Big Rock Commercial",
		category: ["audio", "recording", "editing", "video"],
		image: "images/portfolio/bigrock.png",
		slug: "bigrock",
	},
];

function Portfolios() {
	const [getAllItems] = useState(allData);
	const [dataVisibleCount, setDataVisibleCount] = useState(6);
	const [dataIncrement] = useState(6); // # of items load more loads
	const [activeFilter, setActiveFilter] = useState("");
	const [visibleItems, setVisibleItems] = useState([]);
	const [noMorePost, setNoMorePost] = useState(false);

	useEffect(() => {
		setActiveFilter(filters[0].name.toLowerCase());
		setVisibleItems(getAllItems.filter((item) => item.id <= 6));
	}, [getAllItems]);

	const handleChange = (e) => {
		//console.log(e.target.value);
		e.preventDefault();
		let targetFilter = e.target.value
		  ? e.target.value.toLowerCase()
		  : e.target.textContent.toLowerCase();
		setActiveFilter(targetFilter);
		let tempData;
		if (targetFilter === filters[0].name.toLowerCase()) {
		  setDataVisibleCount(6); // reset dataVisibleCount to 6
		  setVisibleItems(getAllItems.slice(0, 6)); // show first 6 items
		  setNoMorePost(false);
		} else {
		  tempData = getAllItems.filter((data) => data.category.includes(targetFilter));
		  setVisibleItems(tempData);
		  setNoMorePost(true);
		}
	  };

	  const handleLoadmore = (e) => {
		e.preventDefault();
		let tempCount = dataVisibleCount + dataIncrement;
		if (activeFilter === filters[0].name.toLowerCase()) {
		  if (tempCount >= getAllItems.length) {
			setNoMorePost(true);
			setDataVisibleCount(getAllItems.length);
			setVisibleItems(getAllItems.slice(0, getAllItems.length));
		  } else {
			setDataVisibleCount(tempCount);
			setVisibleItems(getAllItems.slice(0, tempCount));
		  }
		} else {
		  let items = getAllItems.filter((data) => {
			return data.category.includes(activeFilter);
		  });
		  setVisibleItems(items);
		}
	  };

	return (
		<>
			<ul className="portfolio-filter list-inline">
				{filters.map((filter) => (
					<li
						className={
							filter.name.toLowerCase() === activeFilter
								? "list-inline-item current"
								: "list-inline-item"
						}
						key={filter.id}
						onClick={handleChange}
					>
						{filter.name}
					</li>
				))}
			</ul>

			<div className="pf-filter-wrapper mb-4">
				<select
					className="portfolio-filter-mobile"
					onChange={(e) => handleChange(e)}
				>
					{filters.map((filter) => (
						<option value={filter.name} key={filter.id}>
							{filter.name}
						</option>
					))}
				</select>
			</div>

			<div className="row portfolio-wrapper">
				{visibleItems.map((item) => (
					<div className="col-md-4 col-sm-6 grid-item" key={item.id}>
						<Portfolio portfolio={item} />
					</div>
				))}
			</div>

			{noMorePost ? null : (
				<div className="load-more text-center mt-4">
					<a
						href="#!"
						className="btn btn-default"
						onClick={(e) => handleLoadmore(e)}
					>
						<i className="fas fa-circle-notch"></i> Load more
					</a>
				</div>
			)}
		</>
	);
}

export default Portfolios;
