import React from "react";
import { Link as ScrollLink } from "react-scroll";
import convertHtmlToReact from "@hedgedoc/html-to-react";

const aboutData = {
	cvpath: "media/Ryan Manning%20-%20Resume.pdf",
	image: "images/about.png",
	name: "Ryan Manning",
	location: "Lethbridge, AB",
	birthday: "April 8th, 1980",
	email: "mail@ryanmanning.ca",
	aboutMe:
		"Ryan Manning is a full-stack web developer and IT support specialist located in Lethbridge, Alberta, with a passion for audio engineering and recording. He has a proven ability to design, develop, and maintain robust web applications, troubleshoot complex technical issues, and produce high-quality audio and video content. He excels at collaborating with cross-functional teams to deliver innovative solutions that exceed expectations.",
};

function About() {
	return (
		<div className="row">
			<div className="col-md-3">
				<img src={aboutData.image} alt={aboutData.name} />
			</div>
			<div className="col-md-9">
				<h2 className="mt-4 mt-md-0 mb-4">Summary</h2>
				<p className="mb-4">{convertHtmlToReact(aboutData.aboutMe)}</p>
				<div className="row my-4 d-none">
					<div className="col-md-6">
						<p className="mb-2">
							Name:{" "}
							<span className="text-dark">{aboutData.name}</span>
						</p>
						<p className="mb-0">
							Birthday:{" "}
							<span className="text-dark">
								{aboutData.birthday}
							</span>
						</p>
					</div>
					<div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
						<p className="mb-2">
							Location:{" "}
							<span className="text-dark">
								{aboutData.location}
							</span>
						</p>
						<p className="mb-0">
							Email:{" "}
							<span className="text-dark">{aboutData.email}</span>
						</p>
					</div>
				</div>
				<a
					href={aboutData.cvpath}
					target="_blank"
					rel="noreferrer"
					className="btn btn-default mr-3 mb-2 mb-sm-0"
				>
					<i className="icon-cloud-download"></i>Download CV
				</a>
				<ScrollLink
					activeClass="active"
					to="contact"
					spy={true}
					smooth={true}
					duration={500}
					offset={50}
					className="btn btn-alt mb-2 mb-sm-0"
				>
					<i className="icon-envelope"></i>Contact me
				</ScrollLink>
			</div>
		</div>
	);
}

export default About;
