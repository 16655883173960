import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useRouteMatch } from "react-router-dom";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaImdb,
  FaYoutube,
  FaEnvelope
} from "react-icons/fa";

const headerData = {
  name: "Ryan Manning",
  designation: "Web Developer  ·  Audio Engineer",
  imageThumb: "/images/logo.png",
  social: {
    linkedin: "https://www.linkedin.com/in/ryanvancemanning/",
    //facebook: "https://facebook.com/ryanvmanning",
    twitter: "https://twitter.com/yanvanman",
    //instagram: "https://twitter.com/yanvanman",
    imdb: "https://www.imdb.com/name/nm1625066/",
    //youtube: "https://www.youtube.com/",
    email: "mailto:mail@ryanmanning.ca"
  },
};

function Header({ toggleHeader, toggleHandler }) {
  const [currentPath, setCurrentPath] = useState("");
  const match = useRouteMatch();

  useEffect(() => {
    setCurrentPath(match.path);
  }, [match]);

  const closeMobileMenu = () => {
    if (window.innerWidth < 992) {
      // trigger click event to close mobile menu if open after scroll link action
      const pushedElements = document.querySelectorAll('.mobile-header.push button.menu-icon');
      if (pushedElements.length > 0) {
        pushedElements.forEach((element) => {
          element.click();
        });
      }      
    }
  }

  return (
    <>
      <div
        className={
          toggleHeader
            ? "mobile-header py-2 px-3 mt-4 push"
            : "mobile-header py-2 px-3 mt-4"
        }
      >
        <button className="menu-icon mr-2" onClick={toggleHandler}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <span className="logo">
          <img src={headerData.imageThumb} alt={headerData.name} />
        </span>
        <span className="site-title dot ml-2 text-white">
          {headerData.name}
        </span>
      </div>

      <header
        className={
          toggleHeader
            ? "left float-left shadow-dark open"
            : "left float-left shadow-dark"
        }
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleHandler}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="header-inner d-flex align-items-start flex-column">
            <img src={headerData.imageThumb} alt={headerData.name} />
          <div className="site-title mt-3">
            {headerData.name}
          </div>

          <span className="site-slogan">{headerData.designation}</span>

          <nav>
            <ul className="vertical-menu scrollspy">
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={-50}
                    duration={500}
                    onSetActive={closeMobileMenu}
                  >
                    <i className="icon-home"></i>Home
                  </ScrollLink>
                ) : (
                  <Link to="/#home">
                    <i className="icon-home"></i>Home
                  </Link>
                )}
              </li>
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="about"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    onSetActive={closeMobileMenu}
                  >
                    <i className="icon-user"></i>About
                  </ScrollLink>
                ) : (
                  <Link to="/#about">
                    <i className="icon-user"></i>About
                  </Link>
                )}
              </li>
              {/* <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-bulb"></i>Services
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-bulb"></i>Services
                  </Link>
                )}
              </li> */}
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="experience"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    onSetActive={closeMobileMenu}
                  >
                    <i className="icon-puzzle"></i>Experience
                  </ScrollLink>
                ) : (
                  <Link to="/#experience">
                    <i className="icon-puzzle"></i>Experience
                  </Link>
                )}
              </li>
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="education"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    onSetActive={closeMobileMenu}
                  >
                    <i className="icon-graduation"></i>Education
                  </ScrollLink>
                ) : (
                  <Link to="/#education">
                    <i className="icon-graduation"></i>Education
                  </Link>
                )}
              </li>
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    onSetActive={closeMobileMenu}
                  >
                    <i className="icon-grid"></i>Portfolio
                  </ScrollLink>
                ) : (
                  <Link to="/#portfolio">
                    <i className="icon-grid"></i>Portfolio
                  </Link>
                )}
              </li>
              {/* <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="blogs"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <i className="icon-pencil"></i>Blog
                  </ScrollLink>
                ) : (
                  <Link to="/">
                    <i className="icon-pencil"></i>Blog
                  </Link>
                )}
              </li> */}
              <li>
                {currentPath === "/" ? (
                  <ScrollLink
                    activeClass="active"
                    to="contact"
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    onSetActive={closeMobileMenu}
                  >
                    <i className="icon-phone"></i>Contact
                  </ScrollLink>
                ) : (
                  <Link to="/#contact">
                    <i className="icon-phone"></i>Contact
                  </Link>
                )}
              </li>
            </ul>
          </nav>

          <div className="footer mt-auto">
            <ul className="social-icons list-inline">
              {!headerData.social.linkedin ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.linkedin} title="LinkedIn" target="_blank" rel="noreferrer">
                    <FaLinkedinIn />
                  </a>
                </li>
              )}
              {!headerData.social.facebook ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.facebook} title="Facebook" target="_blank" rel="noreferrer">
                    <FaFacebookF />
                  </a>
                </li>
              )}              
              {!headerData.social.twitter ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.twitter} title="Twitter" target="_blank" rel="noreferrer">
                    <FaTwitter />
                  </a>
                </li>
              )}
              {!headerData.social.instagram ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.instagram} title="Instagram" target="_blank" rel="noreferrer">
                    <FaInstagram />
                  </a>
                </li>
              )}
              {!headerData.social.imdb ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.imdb} title="IMDB" target="_blank" rel="noreferrer">
                    <FaImdb />
                  </a>
                </li>
              )}
              {!headerData.social.youtube ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.youtube} title="YouTube" target="_blank" rel="noreferrer">
                    <FaYoutube />
                  </a>
                </li>
              )}
              {!headerData.social.email ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.email} title="Email">
                    <FaEnvelope />
                  </a>
                </li>
              )}
            </ul>

            <span className="copyright">
              &copy; {new Date().getFullYear()} All Rights Reserved.
            </span>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
